<template>
  <v-row v-observe-visibility="visibilityChanged"
         class="pa-3 mt-2"
  >
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.token"
    ></alert-error>

    <v-col
      cols="12"
      md="10"
    >
      <v-card>
        <v-card-title>
          <div>
            <h5 class="font-weight-bold text-uppercase">
              {{ $t("ProjectAPIKeys") }}
            </h5>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon
                 color="primary"
                 class="me-3"
                 :loading="loading"
                 :disabled="loading"
                 @click="fetchDatas()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mx-5"></v-divider>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex flex-grow-1  align-center pb-3">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              :placeholder="$t('SearchAPIKey')"
              class="me-3 search-table"
              clearable
              prepend-inner-icon="mdi-magnify"
              clear-icon="mdi-close"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-data-table
          v-model="selectedTableData"
          :headers="tableColumns"
          :items="listTable"
          :options.sync="options"
          :server-items-length="totalListTable"
          :loading="loading"
          :single-select="true"
          class="text-no-wrap"
          :footer-props="{
            'items-per-page-options': [10, 12, 20, 100, 1000, -1],
          }"
        >
          <template
            #[`item.name`]="{item}"
          >
            <span class="d-block primary--text  text-uppercase font-weight-semibold text-truncate">{{ item.name }}</span>
          </template>
          <template
            #[`item.category`]="{item}"
          >
            <span class="d-block text-uppercase font-weight-semibold text-truncate">{{ item.category }}</span>
          </template>
          <template
            #[`item.type`]="{item}"
          >
            <span class="d-block text-uppercase">{{ item.type }}</span>
          </template>

          <template
            #[`item.secret`]="{item}"
          >
            <v-icon v-if="item.secret"
                    color="primary"
            >
              mdi-key-outline
            </v-icon>
          </template>
          <template
            #[`item.mandatory`]="{item}"
          >
            <v-icon v-if="item.mandatory"
                    :color="item.secret ? 'secondary' : 'error'"
            >
              mdi-asterisk
            </v-icon>
          </template>
          <template #[`item.created_at`]="{item}">
            {{ $moment(item.created_at).format("lll") }}
          </template>
          <template #[`item.updated_at`]="{item}">
            {{ $moment(item.updated_at).format("lll") }}
          </template>

          <template #[`item.actions`]="{item}">
            <div class="d-flex align-center justify-center">
              <v-btn
                x-small
                outlined
                class="me-3"
                color="primary"
                @click="editDialog(item)"
              >
                <v-icon>
                  mdi-pencil-outline
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog
      v-model="isAddDialogVisible"
      max-width="450"
    >
      <v-card v-if="token"
              class="pa-sm-6 pa-3"
      >
        <v-card-title
          v-if="token.created_at"
          class="justify-center text-h5 text-uppercase"
        >
          {{ token.name }}
        </v-card-title>
        <v-card-title
          v-else
          class="justify-center text-h5"
        >
          {{ $t("AddToken") }}
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form
            ref="form"
            @submit.prevent="upsertToken"
          >
            <v-textarea
              v-model="token.secret"
              :rules="token.mandatory ? [validators.required] : []"
              :label="token.name"
              required
              outlined
              dense
              prepend-inner-icon="mdi-key"
              hide-details="auto"
              class="mb-4"
            ></v-textarea>
            <div class="mt-6 d-flex justify-center">
              <v-btn
                :loading="loadingAction"
                :disabled="loadingAction"
                color="primary"
                class="me-3"
                type="submit"
              >
                {{ $t('Submit') }}
              </v-btn>
              <v-btn
                outlined
                color="secondary"
                @click="isAddDialogVisible = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ref, getCurrentInstance, onBeforeMount } from 'vue'
import {
  required, emailValidator,
} from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import AlertError from '@/components/AlertError.vue'
import { useUserStore } from '@/stores/user.store'
import { useWorkspacesTokensStore } from '@/stores/workspaces_tokens.store'
import useTokenList from '@/views/workspaces/settings/useTokenList'

export default {
  components: {
    AlertError,
  },
  props: {
  },

  setup() {
    const errors = ref({})
    const loadingAction = ref(false)
    const form = ref(null)
    const token = ref(null)
    const isAlertVisible = ref(false)
    const subscription = ref(null)
    const vm = getCurrentInstance().proxy
    const tokensStore = useWorkspacesTokensStore()
    const userStore = useUserStore()

    const {
      listTable, searchQuery, tableColumns, options, totalListTable, loading, selectedTableData, isAddDialogVisible, fetchDatas,
    } = useTokenList(vm.$router.currentRoute.params.id)

    const visibilityChanged = async isVisible => {
      if (isVisible) {
        fetchDatas()
        subscription.value = await tokensStore.listenTokens(vm.$router.currentRoute.params.id)
      } else {
        userStore.removeChannel(subscription.value)
      }
    }

    onBeforeMount(() => {
      tableColumns.forEach(element => {
        if (element.text) {
        // eslint-disable-next-line no-param-reassign
          element.text = vm.$t(element.text).toUpperCase()
        }
      })
    })

    const openDialog = () => {
      if (form.value) { form.value.reset() }
      isAddDialogVisible.value = true
      token.value = {}
      errors.value = {}
    }

    const editDialog = async _item => {
      isAddDialogVisible.value = true
      tokensStore.getToken(_item.id).then(data => {
        token.value = data
      })
    }

    const upsertToken = () => {
      errors.value = {}

      if (!form.value.validate()) { return }

      if (token.value.created_at) {
        tokensStore.saveToken(token.value).then(() => {
          loadingAction.value = false
          isAddDialogVisible.value = false
        }).catch(error => {
          loadingAction.value = false
          isAlertVisible.value = true
          errors.value = {
            token: error,
          }
        })
      } else {
        tokensStore.createToken(token.value).then(() => {
          loadingAction.value = false
          isAddDialogVisible.value = false
        }).catch(error => {
          loadingAction.value = false
          isAlertVisible.value = true
          errors.value = {
            token: error,
          }
        })
      }
    }

    return {
      tableColumns,
      searchQuery,
      options,
      token,
      totalListTable,
      listTable,
      loading,
      loadingAction,
      selectedTableData,
      validators: {
        required,
        emailValidator,
      },
      isAddDialogVisible,
      errors,
      isAlertVisible,
      form,
      avatarText,
      editDialog,
      openDialog,
      upsertToken,
      visibilityChanged,
      fetchDatas,
    }
  },
}
</script>
