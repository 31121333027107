<template>
  <v-row
    class="pa-3 mt-2"
  >
    <alert-error :is-alert-visible.sync="isAlertVisible"
                 :error="errors.workspace"
    ></alert-error>
    <v-snackbar
      v-model="isSaved"
      outlined
      text
      color="primary"
      shaped
      top
      right
    >
      {{ $t('WorkspaceSavedSuccessfully') }}
      <template #action="{ attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="isSaved = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <confirm-dialog-delete
      :message="$t('DeleteWorkspaceDetails')"
      :is-delete-dialog-visible.sync="isDeleteDialogVisible"
      :is-loading="loadingDelete"
      @confirm-delete="confirmDelete"
    ></confirm-dialog-delete>

    <confirm-dialog
      :is-confirm-dialog-visible.sync="isConfirm"
      :is-loading="loadTrigger"
      @confirm-action="confirmAction"
    ></confirm-dialog>

    <v-col
      cols="12"
      md="4"
    >
      <v-card>
        <v-card-title>
          <h5 class="font-weight-bold text-uppercase">
            {{ $t("General") }}
          </h5>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            class="multi-col-validation"
            :disabled="!$canACL('update', 'workspaces', workspace) || true"
            @submit.prevent="save"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-btn
                  color="primary"
                  outlined
                  small
                  class="me-3 mt-2"
                  @click="viewApp"
                >
                  <v-icon class="me-1"
                          small
                  >
                    mdi-open-in-app
                  </v-icon>
                  {{ $t('ViewApp') }}
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  small
                  class="me-3 mt-2"
                  @click="goSupabase"
                >
                  <v-icon class="me-1"
                          small
                  >
                    mdi-database
                  </v-icon>
                  {{ $t('SupabaseProject') }}
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  small
                  class="me-3 mt-2"
                  :loading="loadTrigger"
                  :disabled="!$canACL('update', 'workspaces', workspace) || loadTrigger"
                  @click="triggerApp"
                >
                  <v-icon class="me-1"
                          small
                  >
                    mdi-cog-refresh
                  </v-icon>
                  {{ $t('TriggerUpdate') }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="workspace.name"
                  :label="$t('Name')"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-home-city"
                  :rules="[validators.required, v => (v || '' ).length <= 25 || $t('FieldMaxLength', {length: 25})]"
                  :placeholder="$t('Name')"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="workspace.settings"
                cols="12"
              >
                <v-text-field
                  v-model="workspace.settings.region"
                  :label="$t('Region')"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-web"
                  :rules="[validators.required]"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="workspace.settings"
                cols="12"
              >
                <v-text-field
                  v-model="workspace.settings.url"
                  :label="$t('ProjectUrl')"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-web"
                  :rules="[validators.required]"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                v-if="workspace.settings?.database"
                cols="12"
              >
                <v-text-field
                  v-model="workspace.settings.database.host"
                  :label="$t('DatabaseHost')"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-database"
                  :rules="[validators.required]"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="false"
                cols="12"
              >
                <v-text-field
                  v-model="workspace.settings.database.version"
                  :label="$t('DatabaseVersion')"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-database"
                  :rules="[validators.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-alert
        border="left"
        colored-border
        text
        color="error"
      >
        <h5 class="font-weight-bold text-uppercase mb-1">
          {{ $t('DangerZone') }}
        </h5>
        <p class="text-sm mb-0">
          {{ $t("DeleteWorkspaceDetails") }}
        </p>
        <v-btn
          color="error"
          class="mt-3 mb-1"
          small
          outlined
          target="_blank"
          :disabled="!$canACL('delete', 'workspaces', workspace)"
          @click="isDeleteDialogVisible= true"
        >
          {{ $t("DeleteWorkspace") }}
        </v-btn>
      </v-alert>
    </v-col>
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="12"
      md="4"
    >
      <v-progress-linear v-if="loadFrame"
                         indeterminate
                         style="width: 450px;"
      ></v-progress-linear>
      <iframe id="frame"
              :src="getUrl()"
              title="Micleo"
              width="910px"
              height="640px"
              @load="onLoadFrame"
      ></iframe>
    </v-col>
  </v-row>
</template>

<script>
import { ref, toRef, getCurrentInstance } from 'vue'
import {
  required,
} from '@core/utils/validation'
import ConfirmDialogDelete from '@/components/ConfirmDialogDelete.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import AlertError from '@/components/AlertError.vue'
import { useWorkspacesStore } from '@/stores/workspaces.store'
import { useTasksStore } from '@/stores/tasks.store'
import { useUserStore } from '@/stores/user.store'

export default {
  components: {
    ConfirmDialogDelete,
    AlertError,
    ConfirmDialog,
  },
  props: {
    workspace: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const loading = ref(false)
    const loadingUpload = ref(false)
    const loadFrame = ref(true)
    const isSaved = ref(false)
    const loadTrigger = ref(false)
    const isConfirm = ref(false)
    const form = ref(null)
    const errors = ref({})
    const isDeleteDialogVisible = ref(false)
    const loadingDelete = ref(false)
    const isAlertVisible = ref(false)
    const vm = getCurrentInstance().proxy
    const workspaceStore = useWorkspacesStore()
    const tasksStore = useTasksStore()
    const userStore = useUserStore()

    const workspace = toRef(props, 'workspace')

    const save = () => {
      errors.value = {}
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      loading.value = true
    }

    const resetForm = () => {
      errors.value = {}
      workspaceStore.getWorkspace(workspace.value.id)
        .then(data => {
          workspaceStore.workspace = data
        })
    }

    const confirmDelete = () => {
      loadingDelete.value = true
      workspaceStore
        .deleteWorkspace(workspace.value)
        .then(() => {
          loadingDelete.value = false
          isDeleteDialogVisible.value = false
          vm.$router.push({ name: 'workspaces' })
        })
        .catch(error => {
          loadingDelete.value = false
          isAlertVisible.value = true
          errors.value = {
            project: error,
          }
        })
    }

    const getUrl = () => `https://${props.workspace.id}.micleo.com`

    const onLoadFrame = () => {
      loadFrame.value = false
    }

    const viewApp = () => {
      window.open(`https://${props.workspace.id}.micleo.com`, '_blank').focus()
    }

    const goSupabase = () => {
      window.open(`https://supabase.com/dashboard/project/${props.workspace.id}`, '_blank').focus()
    }

    const triggerApp = () => {
      isConfirm.value = true
    }

    const confirmAction = () => {
      isConfirm.value = false
      loadTrigger.value = true
      tasksStore.createTask({
        project_id: props.workspace.id,
        sql: false,
        done: false,
        job: JSON.stringify({
          function: 'init-workspace',
          params: {
            workspace_id: props.workspace.id,
            created_by: userStore.user?.id,
          },
        }),
      }).then(() => {
        loadTrigger.value = false
        window.location.href = window.location.href.replace('/0/', '/3/')
      }).catch(() => {
        loadTrigger.value = false
      })
    }

    return {
      workspace,
      form,
      save,
      resetForm,
      confirmDelete,
      isSaved,
      isConfirm,
      loading,
      loadingUpload,
      loadingDelete,
      isAlertVisible,
      errors,
      isDeleteDialogVisible,
      validators: {
        required,
      },
      getUrl,
      onLoadFrame,
      loadFrame,
      viewApp,
      triggerApp,
      confirmAction,
      loadTrigger,
      goSupabase,
    }
  },
}
</script>

<style scoped>
#frame {
    -moz-transform:scale(0.5);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.5);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.5);
    -webkit-transform-origin: 0 0;
    border:2px solid black;
}
</style>
